import React from "react";
import { graphql } from "gatsby";
//Page Disabled For Now
function MissionsPage({ data: { allGraphCmsMission } }) {
  const missions = allGraphCmsMission.edges;
  console.log(missions);
  return (
    <>
      <div>
        {missions.map((mission) => {
          return (
            <div>
              {mission.node.title}
              <img
                src="https://media.graphassets.com/2aksCPMQmmCXWFWelPLL"
                alt="Missions"
              ></img>
            </div>
          );
        })}
      </div>
    </>
  );
}

export const indexPageQuery = graphql`
  {
    allGraphCmsMission(sort: { fields: date, order: ASC }) {
      edges {
        node {
          title
        }
      }
    }
  }
`;

export default MissionsPage;
